import React from "react"
import {ParallaxProvider} from "react-scroll-parallax"
import CheckWebpFeature from "./src/components/common/ggfx-client/module/webp-checker";
CheckWebpFeature();

const windowGlobal = typeof window !== "undefined" && window
export { wrapRootElement } from "./src/apollo/wrap-root-element";
export const wrapPageElement = ({ element, props }) => {
  return <ParallaxProvider>{element}</ParallaxProvider>
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {

  // Avoid to scroll to top when next page trigger click
  // But this feature should condiser with details page back scroll too
  if (location.pathname.match(/property\/for-sale/)) {
    return false;
  }
  if (location.pathname.match(/property\/to-rent/)) {
    return false;
  }

  if (location.pathname.match(/property-map\/for-sale/)) {
    return false;
  }
  if (location.pathname.match(/property-map\/to-rent/)) {
    return false;
  }

  return true;
};