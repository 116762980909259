// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-property-404-js": () => import("./../../../src/pages/Property404.js" /* webpackChunkName: "component---src-pages-property-404-js" */),
  "component---src-pages-property-for-sale-index-js": () => import("./../../../src/pages/property/for-sale/index.js" /* webpackChunkName: "component---src-pages-property-for-sale-index-js" */),
  "component---src-pages-property-map-for-sale-index-js": () => import("./../../../src/pages/property-map/for-sale/index.js" /* webpackChunkName: "component---src-pages-property-map-for-sale-index-js" */),
  "component---src-pages-property-map-to-rent-index-js": () => import("./../../../src/pages/property-map/to-rent/index.js" /* webpackChunkName: "component---src-pages-property-map-to-rent-index-js" */),
  "component---src-pages-property-search-js": () => import("./../../../src/pages/property-search.js" /* webpackChunkName: "component---src-pages-property-search-js" */),
  "component---src-pages-property-to-rent-index-js": () => import("./../../../src/pages/property/to-rent/index.js" /* webpackChunkName: "component---src-pages-property-to-rent-index-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-area-guides-details-js": () => import("./../../../src/templates/area-guides-details.js" /* webpackChunkName: "component---src-templates-area-guides-details-js" */),
  "component---src-templates-default-template-js": () => import("./../../../src/templates/default-template.js" /* webpackChunkName: "component---src-templates-default-template-js" */),
  "component---src-templates-news-details-js": () => import("./../../../src/templates/news-details.js" /* webpackChunkName: "component---src-templates-news-details-js" */),
  "component---src-templates-property-details-js": () => import("./../../../src/templates/property-details.js" /* webpackChunkName: "component---src-templates-property-details-js" */),
  "component---src-templates-team-details-js": () => import("./../../../src/templates/team-details.js" /* webpackChunkName: "component---src-templates-team-details-js" */)
}

